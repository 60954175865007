import GLightbox from "glightbox";

document.addEventListener("DOMContentLoaded", function (event) {

    const lightbox = GLightbox({
        selector: '[data-gallery="gallery"]',
        touchNavigation: true,
        loop: true,
    });


    const registrationtoggles = document.querySelectorAll('[data-behaviour="registrationtoggle"]');
    const registrationforms = document.querySelectorAll('[data-behaviour="registrationform"]');

    if (registrationtoggles.length > 0 && registrationforms.length > 0) {
        registrationtoggles.forEach(registrationtoggle => {
            const registrationform = document.querySelector('[data-behaviour="registrationform"][data-form="' + registrationtoggle.getAttribute('data-form') + '"]');
            const registrationMaxHeight = registrationform.clientHeight;
            registrationform.style.maxHeight = '0px';

            registrationtoggle.addEventListener('click', function () {
                registrationtoggles.forEach(toggle => {
                    toggle.classList.remove('active');
                    toggle.classList.add('inactive');
                    toggle.ariaExpanded = 'false';
                });
                registrationtoggle.classList.add('active');
                registrationtoggle.classList.remove('inactive');
                registrationtoggle.ariaExpanded = 'true';
                registrationforms.forEach(form => {
                    form.style.maxHeight = '0px';
                });
                registrationform.style.maxHeight = (registrationMaxHeight + 300) + 'px';
            });
        });
    }
});

const parallaxes = document.querySelectorAll('[data-behaviour="parallax"]');

function scrollHandler(parallax, parallaxSpeed, parallaxPosition, parallaxMin = -1) {
    const max = -1;
    const scrolledHeight = parallaxPosition;
    const pos = Math.max(Math.min(scrolledHeight / window.innerHeight, parallaxMin), max);
    parallax.style.transform = `translateY(${pos * parallaxSpeed * 100}%)`;
}

if(parallaxes.length > 0){
    parallaxes.forEach(parallax => {
        //parallax effect
        let parallaxSpeed = parallax.getAttribute('data-parallax-speed');
        let parallaxPosition = parallax.getBoundingClientRect().top;
        let parallaxMin = parallax.getAttribute('data-parallax-min') || -1;

        window.addEventListener('scroll', function(){
            scrollHandler(parallax, parallaxSpeed, parallaxPosition, parallaxMin);
            parallaxPosition = parallax.getBoundingClientRect().top;
            parallaxMin = parallax.getAttribute('data-parallax-min');
        });

        scrollHandler(parallax, parallaxSpeed, parallaxPosition, parallaxMin);
    });
}


const header = document.querySelector('.header');

if(header) {
    window.addEventListener('scroll', function(){
        if(window.scrollY > 30){
            header.classList.add('header--sticky');
        }else{
            header.classList.remove('header--sticky');
        }
    });
}


let clickcounters = document.querySelectorAll('[data-behaviour="cc"]'); //cc = clickcounter

if(clickcounters.length > 0){
    clickcounters.forEach(clickcounter => {
        //execute clickcounter by opening a link with fetch
        clickcounter.addEventListener('click', function(e){
            e.preventDefault();
            const inSameWindow = clickcounter.getAttribute('data-cc-samewindow');
            const clickcounterlink = clickcounter.getAttribute("href");

            const currentUrl = window.location.href;
            fetch(currentUrl + "/addlinklog?link=" + clickcounterlink, {
                method: 'GET'
            });
            if(inSameWindow == "true"){
                window.location.href = clickcounterlink;
            } else {
                window.open(clickcounterlink, '_blank');
            }
        });
    });
}


const onclickvideoactivator = document.querySelector('[data-behaviour="onclickvideo_activator"]');
const onclickvideo = document.querySelector('[data-behaviour="onclickvideo"]');
const herovideo = document.querySelector('[data-behaviour="herovideo"]');

if (onclickvideoactivator && onclickvideo && herovideo) {
    herovideo.style.opacity = '1';
    onclickvideo.style.opacity = '0';
    onclickvideoactivator.style.cursor = 'pointer';

    onclickvideoactivator.addEventListener('click', function () {
        herovideo.style.opacity = '0';
        onclickvideo.style.opacity = '1';
        onclickvideoactivator.style.display = 'none';
        const videoToPlay = onclickvideo.querySelector('video');
        videoToPlay.play();

        videoToPlay.addEventListener('ended', function () {
            onclickvideo.style.opacity = '0';
            onclickvideoactivator.style.cursor = 'pointer';
            herovideo.style.opacity = '1';
        });
    });
}
